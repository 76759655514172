import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

const EmailSubscription = () => {
  const [email, setEmail] = useState("");
  const [submissionMsg, setSubmissionMsg] = useState("");
  const [msgStyle, setMsgStyle] = useState("noSubmissionMsg");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.debug("Submit clicked, setting isLoading to true");
    setIsLoading(true);

    let pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!pattern.test(email)) {
      setSubmissionMsg(
        "Above is an invalid email address. <br />Please correct it."
      );
      setMsgStyle(
        "phone-size:errorSubscriptionMsg-ps lg:errorSubscriptionMsg-lg"
      );
      setIsLoading(false);
    } else {
      try {
        console.debug("Calling addToMailchimp");
        const data = await addToMailchimp(email);
        console.debug("Mailchimp response:", data);
        setSubmissionMsg("Thank you for subscribing to our newsletter!");
        setMsgStyle(
          "phone-size:successSubscriptionMsg-ps lg:successSubscriptionMsg-lg"
        );
        setEmail("");
      } catch (error) {
        console.debug("Error in addToMailchimp:", error);
        setSubmissionMsg(
          "Sorry for the inconvenience. <br />Please contact site admin."
        );
        setMsgStyle(
          "phone-size:errorSubscriptionMsg-ps lg:errorSubscriptionMsg-lg"
        );
        setEmail("");
      } finally {
        console.debug("Setting isLoading to false");
        setIsLoading(false);
      }
    }
  };

  const handleEmailChange = (event) => {
    setSubmissionMsg("");
    setEmail(event.currentTarget.value);
  };

  console.debug("Rendering component, isLoading:", isLoading);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 ">
        <input
          placeholder="Email address"
          name="email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          className="rounded h-10 bg-black border border-secondary-light pl-3 text-blue-pale flex-grow outline-none"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-primary-main text-white -ml-1 rounded-r flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Subscribe"
          )}
        </button>
      </div>
      <br />
      <span
        className={msgStyle}
        dangerouslySetInnerHTML={{ __html: submissionMsg }}
      />
    </form>
  );
};

export default EmailSubscription;
